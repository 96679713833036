import {ITheme} from "@cet/constants";

const theme = {
  buttons: {
    Default: {
      background: "var(--color-background-input-field-mutator-controls)",
      borderColor: "transparent",
      borderWidth: 0,
      boxShadow: "var(--effect-drop-shadow-down-medium)",
      color: "var(--color-text-general-secondary-text)",
      cursor: "pointer",
      "&:hover": {
        "&:disabled": {
          background: "var(--color-background-input-field-mutator-controls)",
          boxShadow: "none",
          color: "var(--color-text-button-inactive)",
          cursor: "not-allowed"
        },
        background: "var(--gradient-background-button-default-hover)",
      },
      "&:focus": {
        background: "var(--color-background-input-field-mutator-controls)",
        boxShadow: "var(--effect-drop-shadow-down-medium)",
      },
      "&:active": {
        background: "var(--color-background-input-field-mutator-controls)",
        boxShadow: "var(--effect-inner-shadow-top-medium)",
      },
      "&:disabled": {
        background: "var(--color-background-input-field-mutator-controls)",
        boxShadow: "none",
        color: "var(--color-text-button-inactive)",
        cursor: "not-allowed"
      }
    },
    Primary: {
      background: "var(--color-background-button-continue)",
      borderRadius: "var(--medium-border-radius)",
      borderWidth: "0",
      boxShadow: "var(--effect-drop-shadow-down-medium)",
      color: "var(--color-text-button-primary)",
      cursor: "pointer",
      "&:hover": {
        "&:disabled": {
          background: "var(--color-background-button-continue)",
          boxShadow: "var(--effect-drop-shadow-down-medium)",
          color: "var(--color-text-button-inactive)",
          cursor: "not-allowed"
        },
        background: "var(--gradient-background-button-continue-hover)",
      },
      "&:focus": {
        background: "var(--color-background-button-continue)",
        borderColor: "var(--color-border-alert-continue, transparent)",
        boxShadow: "var(--effect-drop-shadow-down-medium)",
      },
      "&:active": {
        boxShadow: "var(--effect-inner-shadow-top-medium)",
        background: "var(--color-background-button-continue)",
      },
      "&:disabled": {
        background: "var(--color-background-button-continue)",
        boxShadow: "var(--effect-drop-shadow-down-medium)",
        color: "var(--color-text-button-inactive)",
        cursor: "not-allowed"
      },
    },
  } as unknown,
  color: {
    background: {
      primarySurface: {
        value: "var(--color-background-general-primary-surface)",
      },
    },
    surface: {
      primary: {
        active: {
          value: "var(--color-border-general-default);",
        }
      }
    }
  },
  colors: {
    buy: "var(--color-text-general-positive-tick)",
    changeDown: "var(--color-text-general-negative-tick)",
    changeUp: "var(--color-text-general-positive-tick)",
    // error: Colors.red,
    // fundsLoss: Colors.red600,
    // fundsNeutral: Colors.grey800,
    // fundsProfit: Colors.blue600,
    // link: Colors.blue,
    // marketLoss: Colors.red600,
    // marketNeutral: Colors.grey800,
    // marketProfit: Colors.blue600,
    primary: "var(--color-text-active)",
    sell: "var(--color-text-general-negative-tick)",
  } as Partial<ITheme["colors"]>,
  dateRange: {
    background: "var(--color-background-input-field-dropdown-primary)",
    border: "1px solid var(--color-background-input-field-dropdown-primary)",
    color: "var(--color-text-general-inactive-text)",
    disabledColor: "var(--color-text-button-inactive)",
    rangeHoverBackground: "var(--color-background-button-group-selected)",
    selectedBackground: "var(--color-background-button-group-selected)",
    selectedColor: "var(--color-text-active)",
    selectedFirstOrLastBackground: "var(--color-background-button-group-selected)"
  } as unknown as Partial<ITheme["dateRange"]>,
  palette: {
    primary: "var(--color-text-button-primary)",
    white: "var(--color-background-input-field-dropdown-primary)",
    grey100: "var(--color-background-input-field-dropdown-primary)",
    grey200: "var(--color-background-input-field-dropdown-primary)",
  } as Partial<ITheme["colors"]>,
  select: {
    control: {
      border: "none",
      closed: {
        background: "var(--color-background-input-field-dropdown-primary)",
      },
      open: {
        background: "var(--color-background-input-field-dropdown-primary)",
      },
    },
    option: {
      backgroundColor: "var(--color-background-input-field-dropdown-primary)",
      color: "var(--color-text-button-primary)",
      focused: {
        backgroundColor: "var(--color-background-toggle-background-active)",
      },
    },
    singleValue: {
      color: "var(--color-text-button-primary)",
    },
  },
  name: "TastyTrade",
};
export default theme as Partial<ITheme>;
